// Node Modules
import styled from "@emotion/styled";

// Themes
import theme from "@/theme";

interface IStyledButtonProps{
  width: string;
}

export const StyledPrimaryButton = styled.button<IStyledButtonProps>`
  align-items: center;  
  background-color: white;
  border: 3px solid ${theme.borderColors.lightGray};
  border-radius: 4rem;
  color: ${theme.brandColors.primaryGray};
  font-family: ${theme.fontFamilies.arial};
  font-size: 4rem;
  font-weight: bold;
  height: 26.3rem;
  width:${(props) => props.width};

  &:disabled {
    background-color: ${theme.brandColors.breakLineGray};
    cursor: not-allowed;
    opacity: 0.7;

    & svg{
      color: ${theme.brandColors.moderateGreen};
    }
  }

  .btn-container {
    display: flex;
    padding: 3rem 6.5rem;
    width: 100%;
    align-items: center;
  }

  .btn-description-container{
    text-align: left;
    width: 100%;

    & > p:nth-of-type(1){
      font-family: ${theme.fontFamilies.ramaGothicSemiBold};
      font-size: 6rem;
      line-height: 6rem;
      text-transform: uppercase;
      width: 80%;
    }

    & > p:nth-of-type(2){
      font-size: 3.2rem;
      line-height: 3.2rem;
      font-weight: normal;
      padding-top: 1.5rem;
      white-space: pre-wrap;
      width: 70%;
    }
  }

  .btn-icon-container{
    display: flex;
    align-items: center;
    font-size: 3.5rem;
    justify-content: center;
    width: 25%;
  }
  

`;
