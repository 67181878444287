// Components
import Box from "@mui/material/Box";
import Button from "@/gc/button/Button";
import Slider from "@mui/material/Slider";
import {
  ThemeProvider,
  createTheme
} from "@mui/material/styles";

// Hooks
import useSessionStorage from "@/hooks/useSessionStorage";

// Node Modules
import { useState } from "react";

// Scripts
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";
import {
  accessibilitySizeOptions,
  getAccessibilitySize,
  setZoom
} from "@/scripts/accessibilityHelper";

// Services
import dataLayerService from "@/services/dataLayerService";

// Themes
import theme from "@/theme";

// Types
import IContent from "@/interfaces/IContent";
import { ISplashScreenFieldsModified } from "@/interfaces/splash-screen/ISplashScreenFieldsModified";

const FontSizeModal = ({
  content,
}: IContent<ISplashScreenFieldsModified>) => {
  const
    {
      small,
      medium,
      large,
    } = accessibilitySizeOptions;

  const currentZoomValue = getAccessibilitySize(document).zoom;

  const [fontSizeValue, setFontSizeValue] = useState<number>(currentZoomValue ?? small.zoom);

  const {
    applyButtonLabel,
    fontSizeLetter,
  } = content.accesibilityModal?.fields;

  const fontSizeMarks = [
    {
      fontSize: "32px",
      label: fontSizeLetter,
      value: small.zoom,
    },
    {
      fontSize: "48px",
      label: fontSizeLetter,
      value: medium.zoom,
    },
    {
      fontSize: "64px",
      label: fontSizeLetter,
      value: large.zoom,
    },
  ];

  const valueLabelFontSize = fontSizeMarks.find(x => x.value == fontSizeValue)?.fontSize;

  const handleFontSizeChange = (event, newValue) => {
    setFontSizeValue(newValue);
  };

  const valueLabelFormat = () => {
    return fontSizeLetter;
  };

  const muiTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          markLabel: {
            "&[data-index='0']": {
              fontSize: "32px",
            },
            "&[data-index='2']": {
              fontSize: "64px",
            },
            color: theme.fontColors.primaryGray,
            fontSize: "48px",
            lineHeight: "32px",
            paddingTop: "2rem",
          },
          markLabelActive: {
            color: theme.fontColors.primaryGray,
          },
          rail: {
            color: theme.brandColors.breakLineGray,
          },
          root: {
            width: "95%",
          },
          thumb: {
            color: theme.brandColors.white,
            height: "44px",
            width: "44px",
          },
          track: {
            color: theme.brandColors.black,
            height: "5px",
          },
          valueLabel: {
            ":before": {
              height: "26px",
              top: 0,
              transform: "translate(-50%, calc(0% - 5px)) rotate(45deg)",
              width: "26px",
            },
            backgroundColor: theme.brandColors.black,
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
            fontSize: valueLabelFontSize,
            height: "103px",
            left: "50%",
            marginLeft: "-52.5px",
            top: "165px",
            width: "103px",
          },
          valueLabelLabel: {
            fontSize: "60px",
          },
        },
      },
    },
  });

  const applyButtonTopMargin = () => {
    const size = getAccessibilitySize(document);
    switch (size.zoom) {
      case accessibilitySizeOptions.small.zoom:
        return "15rem";
      case accessibilitySizeOptions.medium.zoom:
        return "15rem";
      case accessibilitySizeOptions.large.zoom:
        return "10rem";
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [sessionFontSize, setSessionFontSize] = useSessionStorage(
    "fontSizeZoomValue",
    currentZoomValue,
    true
  );

  const updateFontSize = () => {
    setZoom(document, fontSizeValue);

    setSessionFontSize(fontSizeValue);
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.fontSizeChanged,
      font_size: fontSizeValue,
    });
  };

  return (
    <>
      <div
        className="slider-container"
        data-testid="font-size-slider-container"
      >
        <Box
          sx={{
            width: 829,
          }}
        >
          <ThemeProvider theme={muiTheme}>
            <Slider
              data-testid="font-size-slider"
              getAriaValueText={valueLabelFormat}
              marks={fontSizeMarks}
              max={large.zoom}
              min={small.zoom}
              step={0.1}
              value={Number(fontSizeValue)}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              onChange={handleFontSizeChange}
            />
          </ThemeProvider>
        </Box>
        <div className="apply-button-container">
          <Button
            fontSize="4rem"
            height="12.4rem"
            testId="font-size-apply-button"
            title={applyButtonLabel}
            width="82.4rem"
            onClick={() => updateFontSize()}
          />
        </div>
      </div>
      <style jsx>
        {`
          .apply-button-container{
              margin-top: ${applyButtonTopMargin()};
          }

          .slider-container{
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-top: 6rem;
          }
      `}
      </style>
    </>
  );
};

export default FontSizeModal;
